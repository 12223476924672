import React, { FC } from "react";
import * as style from "./form-title.module.scss";
import { IoDownloadOutline } from "react-icons/io5";

const FormTitle: FC = () => {
  return (
    <section className={style.container}>
      <h1 className={style.form_title}>KVKK Başvuru Formu</h1>
      <a
        download={`KVKK Başvuru Formu`}
        className={style.download_icon}
        href="/forms/KVKK-Başvuru-Formu.pdf"
      >
        <IoDownloadOutline />
      </a>
    </section>
  );
};

export default FormTitle;
