import React, { FC } from "react";
import * as style from "./form.info.module.scss";
const FormInfo: FC = () => {
  return (
    <section className={style.info}>
      <p>
        6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11. ve 13. Maddeleri
        çerçevesindeki talebinizin yerine getirilebilmesi için aşağıdaki başvuru
        formunu eksiksiz doldurarak,
      </p>
      <ul className={style.options}>
        <li>
          Posta yolu ile başvuru formunu iletecekseniz; ıslak imzalı halini
          noter tasdikli TC Kimlik fotokopisi ile birlikte Fenomio İnternet
          Reklamcılık Hizmetleri A.Ş.’nin (Bundan sonra{" "}
          <strong>“Fenomio”</strong> olarak anılacaktır.) adresine posta yolu
          ile zarfın üzerine “Kişisel Verilerin Korunması Kanunu Bilgi Talebi”
          açıklaması yazarak veya bizzat gelerek başvuru zarfını iletecekseniz
          başvuru sahibinin kimliğini tesvik edici belge ile gelerek başvuru
          formunu zarfla (Zarfın üzerine “Kişisel Verilerin Korunması Kanunu
          Bilgi Talebi” açıklaması yazarak) teslim ederek,{" "}
        </li>
        <li>
          Güvenli elektronik imza, Mobil imza ile imzalanarak veya ilgili kişi
          tarafından Fenomio’ya daha önce bildirilen ve Fenomio’nun sisteminde
          kayıtlı bulunan elektronik posta adresinden birini kullanmak suretiyle
          başvuru formunu iletecekseniz, <strong>info@fenomio.com</strong>{" "}
          adresine e-postanın konu kısmına “Kişisel Verilerin Korunması Kanunu
          Bilgi Talebi” açıklaması yazarak iletebilirsiniz.
        </li>
      </ul>
      <p>Ya da aşağıdaki formu doldurarak da başvuru yapabilirsiniz.</p>
    </section>
  );
};

export default FormInfo;
