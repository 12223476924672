// extracted by mini-css-extract-plugin
export var checkbox_input = "form-module--checkbox_input--GOLiq";
export var formCheckLabel = "form-module--form-check-label--De7bd";
export var formModuleCheckbox_inputGOLiq = "form-module--form-module--checkbox_input--GOLiq--5qDu-";
export var form_container = "form-module--form_container--3wQJT";
export var input_container = "form-module--input_container--SMech";
export var link_button = "form-module--link_button--aARfi";
export var radio_input = "form-module--radio_input--0lhnx";
export var section_inputs = "form-module--section_inputs--wE1TI";
export var section_subtitle = "form-module--section_subtitle--tRh0u";
export var section_title = "form-module--section_title--ZM6+Z";
export var submit_button = "form-module--submit_button--Q-pRT";
export var textarea_input = "form-module--textarea_input--nOEBn";
export var toast_message = "form-module--toast_message--Sj7II";
export var tooltip = "form-module--tooltip--07eRi";