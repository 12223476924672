import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { BaseSyntheticEvent, FC, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { IoInformationCircle } from "react-icons/io5";
import * as style from "./form.module.scss";
import { KVKK_FORM_SENDED_EVENT, TOAST_MESSAGE } from "./kvkk-toast-message";
const MANUAL_DELIVER = "Elden Teslim Almak İstiyorum.";
const KVKKForm: FC = () => {
  const [validated, setValidated] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [checkedRights, setCheckedRights] = useState<Array<string>>([]);
  const [fieldsValid, setFieldsValid] = useState({
    privateInfo:false,
    agentInfo:false,
    relationType:false,
    contactOptions:false
  })

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { t } = useTranslation();

  async function handleSubmit(e: BaseSyntheticEvent) {
    if (!executeRecaptcha) return;

    const form = e.currentTarget;
    setSubmitPressed(true);
    const isValid = form.checkValidity();
    setValidated(isValid);
    e.preventDefault();
    if(!isValid){
      e.stopPropagation();
      return;
    }
    if(checkedRights.length===0){
      e.stopPropagation();
      return;
    }
    if(!fieldsValid.agentInfo || !fieldsValid.contactOptions || !fieldsValid.privateInfo || !fieldsValid.relationType){
      e.stopPropagation();
      return;
    }
    const formData = new FormData(form);
    const formDataObj = Object.fromEntries(formData.entries());
    const headers = new Headers();

    const token = await executeRecaptcha("submit");

    headers.append("x-recaptcha-token", token);
    headers.append("content-type", "application/json");

    const delegateFenomioDescription =
      formDataObj.delegateFenomioDescription as string;

    delete formDataObj.delegateFenomioDescription;

    const body = JSON.stringify({
      ...formDataObj,
      rights:checkedRights,
      delegateFenomio:
        delegateFenomioDescription || formDataObj.delegateFenomio,
    });

    const requestOptions = {
      method: "POST",
      headers,
      body,
    };

    fetch(
      `${process.env.GATSBY_API_URL}/api/v1/agreements/send-kvkk-document`,
      requestOptions
    )
      .then(async (response) => {
        if(response.status === 200)
          window.dispatchEvent(
            new CustomEvent(KVKK_FORM_SENDED_EVENT, {
              detail: TOAST_MESSAGE.success,
            })
          );
        else
          window.dispatchEvent(
            new CustomEvent(KVKK_FORM_SENDED_EVENT, {
              detail: TOAST_MESSAGE.error,
            })
          );
      })
      .catch(() =>
        {
          window.dispatchEvent(
            new CustomEvent(KVKK_FORM_SENDED_EVENT, {
              detail: TOAST_MESSAGE.error,
            })
          );
        }
      )
  }

  if (!executeRecaptcha) return null;

  return (
    <>
      <Form
        name="kvkk-form"
        onSubmit={handleSubmit}
        noValidate
        className={style.form_container}
        validated={validated}
      >
        <Container className={style.input_container} fluid>
          <PrivateInfo validationChange={(val)=>{
            setFieldsValid((oldVal)=>{
              return {
                ...oldVal,
                privateInfo:val
              };
            })
          }} formSent={submitPressed} />
          <AgentInfo validationChange={(val)=>{
            setFieldsValid((oldVal)=>{
              return {
                ...oldVal,
                agentInfo:val
              };
            })
          }}/>
          <RelationType formSent={submitPressed} validationChange={(val)=>{
            setFieldsValid((oldVal)=>{
              return {
                ...oldVal,
                relationType:val
              };
            })
          }}/>
          <Requests checkedItems={checkedRights} setCheckedItems={(val)=>{setCheckedRights(val);}} />
          <ContactOptions formSent={submitPressed} validationChange={(val)=>{
            setFieldsValid((oldVal)=>{
              return {
                ...oldVal,
                contactOptions:val
              };
            })
          }}/>
        </Container>
        <Button
          className={style.submit_button}
          style={{ width: "100%", borderRadius: "10px" }}
          type="submit"
        >
          Formu gönder
        </Button>
      </Form>
    </>
  );
};

export default KVKKForm;

const PrivateInfo = (props:{formSent:boolean, validationChange:(val:boolean)=>void}) => {
  const [nameSurnameIsInvalid, setNameSurnameIsInvalid] = useState<boolean|undefined>(undefined);
  const [phoneNumberIsInvalid, setPhoneNumberIsInvalid] = useState<boolean|undefined>(undefined);
  const [emailIsInvalid, setEmailIsInvalid] = useState<boolean|undefined>(undefined);
  const [tcNumberIsInvalid, setTcNumberIsInvalid] = useState<boolean|undefined>(undefined);
  const [addressIsInvalid, setAddressIsInvalid] = useState<boolean|undefined>(undefined);

  useEffect(()=>{
    if(props.formSent){
      if(nameSurnameIsInvalid===undefined)
        setNameSurnameIsInvalid(true);
      if(phoneNumberIsInvalid===undefined)
        setPhoneNumberIsInvalid(true);
      if(emailIsInvalid===undefined)
        setEmailIsInvalid(true);
      if(tcNumberIsInvalid===undefined)
        setTcNumberIsInvalid(true);
      if(addressIsInvalid===undefined)
        setAddressIsInvalid(true);
    }
  },[props.formSent]);
  useEffect(()=>{
    if(
        nameSurnameIsInvalid === false &&
        phoneNumberIsInvalid === false &&
        emailIsInvalid === false &&
        tcNumberIsInvalid === false &&
        addressIsInvalid === false
      )
      props.validationChange(true);
    else
      props.validationChange(false);
  },[nameSurnameIsInvalid, phoneNumberIsInvalid, emailIsInvalid, tcNumberIsInvalid, addressIsInvalid]);
  return (
    <section>
      <h5 className={style.section_title}>Kişisel Bilgiler*</h5>
      <span className={style.section_subtitle}>
        Bu alanların doldurulması zorunludur.
      </span>
      <Row className={style.section_inputs}>
        <Form.Group
          as={Col}
          sm={12}
          md={6}
          lg={4}
          controlId="private-nameSurname"
        >
          <Form.Control
            name="nameSurname"
            type="text"
            placeholder="Adınız Soyadınız"
            size="lg"
            required
            onChange={(e)=>{
              var val = e.target.value;
              if(!new RegExp(/^([^0-9]*)$/).test(val) || val.length===0)
                setNameSurnameIsInvalid(true);
              else
                setNameSurnameIsInvalid(false);
            }}
            isInvalid={nameSurnameIsInvalid}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen geçerli bir ad soyad giriniz.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={6}
          lg={4}
          controlId="private-tcNo"
        >
          <Form.Control
            name="tcNo"
            type="number"
            placeholder="T.C. Kimlik No"
            size="lg"
            required
            onChange={(e)=>{
              if(e.target.value.length>11 || e.target.value.length<11)
                setTcNumberIsInvalid(true);
              else
                setTcNumberIsInvalid(false);
            }}
            isInvalid={tcNumberIsInvalid}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen 11 haneli T.C. Kimlik Numaranızı giriniz.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={6}
          lg={4}
          controlId="private-email"
        >
          <Form.Control
            name="email"
            type="email"
            placeholder="E-mail adresiniz"
            size="lg"
            required
            onChange={(e)=>{
              const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
              setEmailIsInvalid(!emailRegex.test(e.target.value))

            }}
            isInvalid={emailIsInvalid}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen geçerli bir e-mail adresi giriniz.
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group
          as={Col}
          sm={12}
          md={6}
          lg={4}
          controlId="private-phoneNumber"
        >
          <Form.Control
            name="phoneNumber"
            type="number"
            placeholder="Telefon Numarası"
            size="lg"
            required
            onChange={(e)=>{
              if(e.target.value.length>20 || e.target.value.length<10)
                setPhoneNumberIsInvalid(true);
              else
                setPhoneNumberIsInvalid(false);
            }}
            isInvalid={phoneNumberIsInvalid}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen geçerli bir telefon numarası giriniz.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={4}
          controlId="private-address"
        >
          <Form.Control
            name="address"
            type="text"
            placeholder="Açık Adres"
            size="lg"
            required
            onChange={(e)=>{
              const reg = new RegExp('^[0-9]+$');
              const val = e.target.value;
              if(reg.test(val) || val.length<3)
                setAddressIsInvalid(true);
              else
                setAddressIsInvalid(false);
            }}
            isInvalid={addressIsInvalid}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen geçerli bir açık adres giriniz.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </section>
  );
};

const AgentInfo = (props:{validationChange:(val:boolean)=>void}) => {
  const [nameSurnameIsInvalid, setNameSurnameIsInvalid] = useState<boolean|undefined>(undefined);
  const [tcNumberIsInvalid, setTcNumberIsInvalid] = useState<boolean|undefined>(undefined);
  useEffect(()=>{
    if(!nameSurnameIsInvalid && !tcNumberIsInvalid)
      props.validationChange(true);
    else
      props.validationChange(false);
  },[nameSurnameIsInvalid, tcNumberIsInvalid]);

  return (
    <section>
      <h5 className={style.section_title}>Temsilci Hakkında(Varsa)</h5>
      <Row className={style.section_inputs}>
        <Form.Group
          as={Col}
          sm={12}
          md={6}
          controlId={`agent-delegateNameSurname`}
        >
          <Form.Control
            name="delegateNameSurname"
            type="text"
            placeholder="Adınız Soyadınız"
            size="lg"
            onChange={(e)=>{
              var val = e.target.value;
              if(!new RegExp(/^([^0-9]*)$/).test(val))
                setNameSurnameIsInvalid(true);
              else
                setNameSurnameIsInvalid(false);
            }}
            isInvalid={nameSurnameIsInvalid}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen temsilci adını ve soyadını giriniz.
          </Form.Control.Feedback>
        </Form.Group>



        <Form.Group
          as={Col}
          sm={12}
          md={6}
          controlId="agent-delegateTcNo"
        >
          <Form.Control
            name="delegateTcNo"
            type="number"
            placeholder="T.C. Kimlik No"
            size="lg"
            onChange={(e)=>{
              if(e.target.value.length!==0 && e.target.value.length!==11)
                setTcNumberIsInvalid(true);
              else
                setTcNumberIsInvalid(false);
            }}
            isInvalid={tcNumberIsInvalid}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen geçerli bir T.C kimlik numarası giriniz.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </section>
  );
};

const RelationType = (props:{formSent:boolean,validationChange:(val:boolean)=>void}) => {
  const {t} = useTranslation();
  const [showDescription, setShowDescription] = useState(false);
  const [showYearSelection, setShowYearSelection] = useState(false);
  const [delegateFenomioIsInvalid, setDelegateFenomioIsInvalid] = useState<boolean|undefined>(undefined);
  const [oldEmployeeStartDateIsInvalid, setOldEmployeeStartDateIsInvalid] = useState<boolean|undefined>(undefined);
  const [oldEmployeeEndDateIsInvalid, setOldEmployeeEndDateIsInvalid] = useState<boolean|undefined>(undefined);
  const [delegateFenomioDescriptionIsInvalid,setDelegateFenomioDescriptionIsInvalid] = useState<boolean|undefined>(undefined);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [invalidDate, setInvalidDate] = useState(false);
  const options = [
    "İş Ortağı",
    "Müşteri",
    "Çalışan/Stajyer Adayı",
    "Eski Çalışan",
    "Üçüncü Kişi Firma Çalışanı",
    "Ziyaretçi",
    "Katılımcı",
    "Kefil",
    "Diğer (Açıklayınız)",
  ];

  useEffect(()=>{
    if(props.formSent){
      if(delegateFenomioIsInvalid===undefined)
        setDelegateFenomioIsInvalid(true);
      if(showDescription && delegateFenomioDescriptionIsInvalid===undefined)
        setDelegateFenomioDescriptionIsInvalid(true);
      if(showYearSelection && oldEmployeeStartDateIsInvalid===undefined)
        setOldEmployeeStartDateIsInvalid(true);
      if(showYearSelection && oldEmployeeEndDateIsInvalid===undefined)
        setOldEmployeeEndDateIsInvalid(true);
    }
  },[props.formSent, showDescription, showYearSelection]);

  useEffect(()=>{
    if(
        delegateFenomioIsInvalid === true ||
        (showDescription && delegateFenomioDescriptionIsInvalid === true) ||
        (showYearSelection && oldEmployeeStartDateIsInvalid === true) ||
        (showYearSelection && oldEmployeeEndDateIsInvalid === true) ||
        (showYearSelection && invalidDate === true)
      ){
      props.validationChange(false);
    }
    else
      props.validationChange(true);
  },[delegateFenomioIsInvalid, delegateFenomioDescriptionIsInvalid, oldEmployeeStartDateIsInvalid, oldEmployeeEndDateIsInvalid, invalidDate, showDescription, showYearSelection]);

  useEffect(()=>{
    if(!startDate || !endDate)
      return;
    const start = new Date(startDate);
    const end = new Date(endDate);
    if(start>end){
      setOldEmployeeStartDateIsInvalid(true);
      setOldEmployeeEndDateIsInvalid(true);
      setInvalidDate(true);
    }
    else
    {
      setOldEmployeeStartDateIsInvalid(false);
      setOldEmployeeEndDateIsInvalid(false);
      setInvalidDate(false);
    }
  },[startDate,endDate]);

  return (
    <section>
      <h5 className={style.section_title}>Fenomio ile Olan İlişki</h5>
      <Row className={style.section_inputs}>
        <Form.Group as={Col} sm={12} controlId="delegateFenomio">
          <Form.Select
            className="form-control"
            size="lg"
            name="delegateFenomio"
            isInvalid={delegateFenomioIsInvalid}
            onChange={(e: BaseSyntheticEvent) => {
              if(e.target.value==="Seçiniz")
                setDelegateFenomioIsInvalid(true);
              else
                setDelegateFenomioIsInvalid(false);
              setShowDescription(e.target.value === "Diğer (Açıklayınız)");
              setShowYearSelection(e.target.value === "Eski Çalışan")
            }}
            required
          >
            <option>Seçiniz</option>
            {options.map((option, index) => (
              <option value={option} key={`option-key${index}`}>
                {option}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
              Lütfen bir seçenek giriniz.
            </Form.Control.Feedback>
        </Form.Group>
        {showDescription && (
          <Form.Group as={Col} sm={12} controlId="delegateFenomioDescription">
            <Form.Control
              name="delegateFenomioDescription"
              type="text"
              size="lg"
              isInvalid={delegateFenomioDescriptionIsInvalid}
              required
              onChange={(e)=>{
                if(e.target.value.length===0)
                  setDelegateFenomioDescriptionIsInvalid(true);
                else
                  setDelegateFenomioDescriptionIsInvalid(false);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Lütfen bir açıklama giriniz.
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {showYearSelection && (
          <>
            <Form.Group as={Col} sm={12} md={6} controlId="oldEmployeeStartDate">
              <label>İş başlangıç tarihi</label>
              <Form.Control
                name="oldEmployeeStartDate"
                type="date"
                min={'2020-01-01'}
                max={new Date().toISOString().split("T")[0]}
                size="lg"
                isInvalid={oldEmployeeStartDateIsInvalid}
                required
                value={startDate}
                onChange={(e)=>{
                  setStartDate(e.target.value);
                  if(e.target.value.length===0)
                    setOldEmployeeStartDateIsInvalid(true);
                  else
                    setOldEmployeeStartDateIsInvalid(false);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {invalidDate?<>Bitiş tarihi başlangıç tarihinden daha erken olamaz.</>:<>Lütfen geçerli bir başlangıç tarihi giriniz.</>}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={12} md={6} controlId="oldEmployeeEndDate">
              <label>İş bitiş tarihi</label>
              <Form.Control
                name="oldEmployeeEndDate"
                type="date"
                min={'2020-01-01'}
                max={new Date().toISOString().split("T")[0]}
                size="lg"
                isInvalid={oldEmployeeEndDateIsInvalid}
                required
                value={endDate}
                onChange={(e)=>{
                  setEndDate(e.target.value);
                  if(e.target.value.length===0)
                    setOldEmployeeEndDateIsInvalid(true);
                  else
                    setOldEmployeeEndDateIsInvalid(false);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {invalidDate?<>Bitiş tarihi başlangıç tarihinden daha erken olamaz.</>:<>Lütfen geçerli bir bitiş tarihi giriniz.</>}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}
      </Row>
    </section>
  );
};

const Requests = (props:{checkedItems:Array<string>, setCheckedItems:(newVal:Array<string>)=>void}) => {
  const checkboxes = [
    "Kişisel verilerimin işlenip işlenmediğini öğrenmek ",
    "Kişisel verilerimin işlenmiş olması durumunda buna ilişkin bilgi talep etme",
    "Kişisel verilerimin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme",
    "Yurt içinde veya yurt dışında kişisel verilerimin aktarıldığı üçüncü kişileri bilme",
    "Kişisel verilerimin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme",
    "Kişisel verilerimin bu Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalktığını düşünüyorum ve bu çerçevede kişisel verilerimin silinmesini veya yok edilmesini isteme",
    "Kişisel verilerimin düzeltilmesi, silinmesi ya da yok edilmesi halinde bu işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme",
    "İşlenen verilerimin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhime bir sonucun ortaya çıkmasına itiraz etme",
    "Kişisel verilerimin  kanuna  aykırı  olarak  işlenmesi  sebebiyle  zarara uğramam hâlinde zararın giderilmesini talep etme",
  ];

  return (
    <section>
      <h5 className={style.section_title}>
        Kullanılması Talep Edilen Hak
        <Button variant="link" className={style.link_button}>
          <IoInformationCircle />
        </Button>
      </h5>
      
      <Form.Group controlId="rights">
        {checkboxes.map((label: string, index) => (
          <Form.Check
            className={style.checkbox_input}
            name={`rights`}
            type="checkbox"
            id={`right-${index}`}
            key={`right-${index}`}
            label={label}
            value={label}
            onChange={(e)=>{
              let newVal = [...props.checkedItems];
                if(props.checkedItems.includes(label))
                  newVal = newVal.filter(x=>x!==label);
                else
                  newVal.push(label);
              props.setCheckedItems(newVal);
            }}
          />
        ))}
      </Form.Group>
      {props.checkedItems.length===0 && <p style={{color:'red', marginTop:'1rem', fontSize:'.875rem'}}>Lütfen en az bir seçeneği işaretleyiniz.</p>}

      <Form.Control
        className={style.textarea_input}
        name="description"
        as="textarea"
        rows={4}
        placeholder="Başvuruya İlişkin Açıklamalar"
        size="lg"
      />
    </section>
  );
};

const ContactOptions = (props:{formSent:boolean,validationChange:(val:boolean)=>void}) => {
  const [selectedRadioItem, setSelectedRadioItem] = useState<string|undefined>(undefined);
  const [selectedAnswerChoice, setSelectedAnswerChoice] = useState<string|undefined>(undefined);
  const [otherAnswerValue, setOtherAnswerValue] = useState('');
  const [otherAnswerChoiceIsInvalid, setOtherAnswerChoiceIsInvalid] = useState<boolean|undefined>(undefined);
  useEffect(()=>{
    if(props.formSent){
      if(!selectedRadioItem)
        setSelectedRadioItem('');
      if(!selectedAnswerChoice)
        setSelectedAnswerChoice('');
      if(otherAnswerChoiceIsInvalid===undefined)
        setOtherAnswerChoiceIsInvalid(true);
    }
  },[props.formSent]);
  useEffect(()=>{
    if(!selectedRadioItem)
      props.validationChange(false);
    else if(selectedRadioItem!==MANUAL_DELIVER && !selectedAnswerChoice)
      props.validationChange(false);
    else if(selectedRadioItem!==MANUAL_DELIVER && selectedAnswerChoice==="other" && otherAnswerChoiceIsInvalid)
        props.validationChange(false);
    else
      props.validationChange(true);
  },[otherAnswerChoiceIsInvalid, selectedRadioItem, selectedAnswerChoice]);
  return (
    <>
    <section>
      <h5 className={style.section_title}>
        Yanıt için İletişim Tercihinizi İşaretleyiniz:
      </h5>

      <Form.Check
        className={style.radio_input}
        inline
        type="radio"
        name="answerContact"
        value="E-mail"
        id={`E-mail`}
        label={"E-mail"}
        onChange={(e)=>{
          setSelectedRadioItem(e.target.value);
          setSelectedAnswerChoice('');
          setOtherAnswerValue('');
        }}
        required
        isInvalid={selectedRadioItem===''}
      />
      <Form.Check
        className={style.radio_input}
        inline
        type="radio"
        name="answerContact"
        value="Posta"
        id={`Post`}
        label={"Posta"}
        onChange={(e)=>{
          setSelectedRadioItem(e.target.value);
          setSelectedAnswerChoice('');
          setOtherAnswerValue('');
        }}
        required
        isInvalid={selectedRadioItem===''}
      />

      <Form.Check
        className={style.radio_input}
        inline
        type="radio"
        name="answerContact"
        value={MANUAL_DELIVER}
        id={`other`}
        label={MANUAL_DELIVER}
        onChange={(e)=>{
          setSelectedRadioItem(e.target.value);
          setSelectedAnswerChoice('');
          setOtherAnswerValue('');
        }}
        required
        isInvalid={selectedRadioItem===''}
      />

      <OverlayTrigger
        overlay={(props) => (
          <Tooltip className={style.tooltip} id={"tooltip-other"} {...props}>
            Bu seçenek için ekibimiz size bir rezervasyon oluşturacaktır. Bu
            formdaki telefon numarası ya da e-mail adresiniz üzerinden size
            ulaşacağız. Posta ya da e-mail seçeneklerini seçerseniz daha hızlı
            sonuç alabileceğinizi belirtmek isteriz.
          </Tooltip>
        )}
        placement="top"
        delay={{ show: 200, hide: 400 }}
      >
        <Button variant="link" className={style.link_button}>
          <IoInformationCircle />
        </Button>
      </OverlayTrigger>
      {selectedRadioItem==='' && <p style={{color:'red', marginTop:'1rem', fontSize:'.875rem'}}>Lütfen bir seçeneği işaretleyiniz.</p>}
    </section>

    {(selectedRadioItem==='E-mail' || selectedRadioItem === 'Posta') && <section>
      <h5 className={style.section_title}>
        {selectedRadioItem} İletişim Tercihi:
      </h5>
      <Row>
      <Form.Group as={Col}
        xs={12}
       
        md={6}>
        <Form.Check
          
          className={style.radio_input}
          style={{margin:'0'}}
          inline
          type="radio"
          name="answerChoice"
          checked={selectedAnswerChoice==="in-form"}
          value="in-form"
          id={`inlineField`}
          label={selectedRadioItem==="E-mail"?"Bu formda doldurduğum e-posta adresime gönderilsin":"Bu formda doldurduğum açık adresime postalansın"}
          onChange={(e)=>{
            setSelectedAnswerChoice(e.target.value);
          }}
          required
          isInvalid={!selectedAnswerChoice}
        />
      </Form.Group>
      <Col style={{display:'flex', flexDirection:'row', gap:'0', alignItems:'start', flex:'1'}}>
        <Form.Check
          className={style.radio_input}
          inline
          type="radio"
          name="answerChoice"
          checked={selectedAnswerChoice==="other"}
          value="other"
          id={`other`}
          label=""
          style={{margin:'0', marginTop:'6px'}}
          onChange={(e)=>{
            setSelectedAnswerChoice(e.target.value);
          }}
          required
          isInvalid={!selectedAnswerChoice}
        />
        <Form.Group
          controlId="private-otherAnswerChoice"
          style={{width:'100%'}}
        >
          <Form.Control
            name="otherAnswerChoice"
            type="text"
            placeholder={selectedRadioItem==="E-mail"?"Postayı almak istediğiniz e-posta adresini yazınız.":"Postayı almak istediğiniz adresi açık şekilde yazınız."}
            size="lg"
            required
            disabled={selectedAnswerChoice!=="other"}
            value={otherAnswerValue}
            onChange={(e)=>{
              setOtherAnswerValue(e.target.value);
              var val = e.target.value;
              var regex = new RegExp('^[0-9]+$');
              if(selectedRadioItem==="E-mail"){
                regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
                if(!regex.test(e.target.value))
                  setOtherAnswerChoiceIsInvalid(true);
                else
                  setOtherAnswerChoiceIsInvalid(false);
              }
              else{
                if(regex.test(val) || val.length<3)
                setOtherAnswerChoiceIsInvalid(true);
              else
                setOtherAnswerChoiceIsInvalid(false);
              }
            }}
            isInvalid={otherAnswerChoiceIsInvalid && selectedAnswerChoice==="other"}
          />
          <Form.Control.Feedback type="invalid">
            Lütfen yanıt için bu alanı doldurunuz.
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      </Row>
      
      {selectedAnswerChoice==='' && <p style={{color:'red', marginTop:'1rem', fontSize:'.875rem'}}>Lütfen bir seçeneği işaretleyiniz.</p>}
    </section>}
    </>
  );
};
