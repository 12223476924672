import { t } from "i18next";
import React, { FC, useEffect, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";

export enum TOAST_MESSAGE {
  close,
  success,
  error,
}

export const KVKK_FORM_SENDED_EVENT = "KVKK_FORM_SENDED_EVENT";

export const KVKKToast: FC = () => {
  const [message, setMessage] = useState<TOAST_MESSAGE>(TOAST_MESSAGE.close);

  useEffect(() => {
    function listener(e: CustomEvent<TOAST_MESSAGE>) {
      setMessage(e.detail);
    }

    window.addEventListener(KVKK_FORM_SENDED_EVENT, listener as EventListener);

    return () => {
      window.removeEventListener(
        KVKK_FORM_SENDED_EVENT,
        listener as EventListener
      );
    };
  }, []);

  return (
    <div style={{ zIndex: 1, position: "fixed", right: "3rem", top: "2.5rem" }}>
      <Toast
        onClose={() => setMessage(TOAST_MESSAGE.close)}
        show={message !== TOAST_MESSAGE.close}
        delay={2000}
        animation
        bg={message === TOAST_MESSAGE.success ? "success" : message === TOAST_MESSAGE.error ? "danger": undefined}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">{t("common.info")}</strong>
        </Toast.Header>
        <Toast.Body>
          <p
            style={{ color: "#fff", padding: "1rem 2rem", fontSize: "1.2rem" }}
          >
            {message === TOAST_MESSAGE.success
              ? "KVKK Başvuru Formunuz başarıyla iletilmiştir.":
              message === TOAST_MESSAGE.close 
              ?
              ""
              : "KVKK başvuru formunuzu gönderirken bir hata oluştu."}
          </p>
        </Toast.Body>
      </Toast>
    </div>
  );
};
