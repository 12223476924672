import React from "react";
import * as style from "./kvkk-form-layout.module.scss";
import FormInfo from "./form-info";
import KVKKForm from "./form";
import FormTitle from "./form-title";

const KVKKFormLayout = () => {
  return (
    <article className={style.container}>
      <FormTitle />
      <div className={style.form_container}>
        <FormInfo />

        <KVKKForm />
      </div>
    </article>
  );
};

export default KVKKFormLayout;
