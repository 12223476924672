import React, { FC } from "react";
import * as style from "./description.module.scss";
const Description: FC = () => {
  return (
    <article className={style.container}>
      <section className={style.description_section}>
        <h5 className={style.section_title}>
          Kişisel Veri Sahibinin Taleplerinin Sonuçlandırılması:
        </h5>
        <p>
          Niteliğine göre talebiniz, KVKK uyarınca bizlere ulaştığı tarihi takip
          eden en kısa sürede ve en geç otuz (30) gün içinde
          cevaplandırılacaktır. Cevaplarımız ve değerlendirmelerimiz, işbu
          Başvuru Formu’nda belirtilmiş olan seçiminize göre KVKK’nın 13.
          maddesi uyarınca yazılı veya elektronik ortam vasıtasıyla tarafınıza
          iletilecektir.
        </p>
        <p>
          Talepleriniz Fenomio tarafından ücretsiz sonuçlandırılacak olup,
          cevaplama sürecinin ayrıca bir maliyet doğurması halinde, ilgili
          mevzuat çerçevesinde belirlenen tutarlarda ücret talep
          edilebilecektir.
        </p>
        <p>
          Başvurunuzun zamanında yanıtlanabilmesi için, işbu formdaki tüm
          bilgileri eksiksiz olarak doldurmanız gerekmektedir. Aksi durumda
          sizlere yanıt verilebilmesi için önce eksik bilgilerin tamamlanması
          talep edilecektir. Bu durum da başvurunuzun yanıtlanması süresini
          uzatabilecektir.
        </p>
      </section>

      <section className={style.description_section}>
        <h5 className={style.section_title}>
          Veri Sahibi (İlgili Kişi) Adına Başvuruda Bulunan Kişiler:{" "}
        </h5>
        <p>
          Veri sahibi adına 3. kişiler tarafından yapılacak olan başvurularda,
          işbu Başvuru Formu ile birlikte noterce onaylanmış özel
          vekâletnamenin, velayet/vesayet altında bulunan çocuklar adına
          yapılacak başvurularda ise işbu Başvuru Formu ile birlikte
          velayet/vesayet ilişkisini tevsik edici belgelerin bir suretinin
          tarafımıza gönderilmesi gerekmektedir.
        </p>
      </section>

      <section className={style.description_section}>
        <h5 className={style.section_title}>
          Veri Sahibi (İlgili Kişi) / Temsilcisi Olarak Beyanım:{" "}
        </h5>
        <p>
          Bu başvuruyu; “Veri Sahibi” veya “Veri Sahibi’nin kanuni temsilcisi”
          sıfatıyla kanunen yapmaya yetkili olduğumu, başvuruda yer alan bilgi
          ve belgelerin güncel ve doğru olduğunu, hukuka aykırı, yanlış ve
          yanıltıcı hiçbir bilgi sağlamadığımı kabul ve beyan ederim.
        </p>
        <p>
          Yetkisiz bir başvuru yapmam halinde başvurum üzerine kanuna aykırı
          veya haksız bir şekilde kişisel verilere dair bilgilerin
          paylaşılmasına neden olabileceğimi ve bundan dolayı Fenomio’nun ve
          ilgili kişilerin doğabilecek zararlarından, başvuruyu yapan olarak
          sorumlu olacağımı kabul ederim.
        </p>
        <span>
          Kişisel Veri Sahibi (İlgili Kişi) / Veri Sahibi (İlgili Kişi) Adına
          Başvuruda Bulunan Kişi
        </span>
        <span>Adı Soyadı :</span>
        <span>Başvuru Tarihi:</span>
        <span>İmza :</span>
      </section>

      <section className={style.description_section}>
        <h5 className={style.section_title}>Başvurunun Gönderileceği Adres:</h5>
        <p>
          Fenomio İnternet Reklamcılık Hizmetleri Ticaret Anonim Şirketi ADALET
          MAH. MANAS BLV. NO: 47 B İÇ KAPI NO: 2809 Bayraklı, İzmir
          <u> info@fenomio.com</u>
        </p>
      </section>
    </article>
  );
};

export default Description;
