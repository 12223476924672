import React from "react";
import { graphql } from "gatsby";
import BaseLayout from "../components/layouts/base-layout";
import HeaderLayout from "../components/layouts/header-layout";
import { Container } from "react-bootstrap";
import KVKKForm from "../components/layouts/kvkk/kvkk-form.layout";
import Description from "../components/layouts/kvkk/description";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { KVKKToast } from "../components/layouts/kvkk/kvkk-toast-message";

const KVKK = () => {
  return (
    <BaseLayout>
      <HeaderLayout />
      <KVKKToast />
      <Container>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY as string}
        >
          <KVKKForm />
        </GoogleReCaptchaProvider>
        <Description />
      </Container>
    </BaseLayout>
  );
};

export default KVKK;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
